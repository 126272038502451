<template>
      <v-card >
        <v-list flat subheader three-line>
          <v-toolbar color="accent" flat>
            <template>
              <v-subheader class="accent text-h6"><b>
                  <v-icon class="pr-2 text-h4">mdi-cohort-variant</v-icon>{{userCohort.title}}
                </b></v-subheader>
              <v-tooltip  top  color="orange">
                <template v-slot:activator="{ on, attr }">
                <v-btn v-on="on" v-bind="attr" @click="revokeAccess()" :disabled="selectedDatasetsToRevoke.length == 0"   color="orange" fab :dark="selectedDatasetsToRevoke.length > 0" x-large absolute bottom right>
                  <v-icon>mdi-lock-off</v-icon>
                </v-btn>
                </template>
                <span >Revoke access to the selected databases</span>
              </v-tooltip>

            </template>
          </v-toolbar>
          <v-divider class="mb-7  "></v-divider>

          <v-list-item-group v-model="selectedDatasetsToRevoke" multiple active-class="">
            <v-list-item v-for="dataset in userCohort.datasets" :key="dataset.id">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{dataset.name}}</v-list-item-title>
                  <v-list-item-subtitle><b>{{dataset.datasetType.name}}</b> {{dataset.recordCount}} rows, {{dataset.columnCount}} columns </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
</template>

<script>

  export default {
    name:'UserCohortDatasets',
    props :{
      userCohort:{
        id:'',
        title:'',
        datasets:[]
      }
    },
    data () {
      return {
        selectedDatasetsToRevoke: [],
      }
    },
    computed:{
      selectedDatasetIdsToRevoke(){
        return this.selectedDatasetsToRevoke.map(idx => this.userCohort.datasets[idx].id )
      }
    },
    created () {
    },
    methods: {
      revokeAccess(){
        this.$emit('onRevokeAccess', this.userCohort.id, this.selectedDatasetIdsToRevoke)
        this.selectedDatasetsToRevoke=[]
      }
    }
  }
</script>