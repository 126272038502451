<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="8" class="px-4">
      <v-card class="mx-auto">

      </v-card>
      <v-card class="mx-auto">
        <v-list flat subheader three-line>
          <v-toolbar color="accent" flat>
            <template>
              <v-subheader class="accent"><b>
                  <v-icon class="pr-2">mdi-database-lock</v-icon>GRANT ACCESS TO SAMPLES IN A COHORT
                </b></v-subheader>
              <v-tooltip  top  color="primary">
                <template v-slot:activator="{ on, attr }">
                <v-btn v-on="on" v-bind="attr" @click="grantAccess()" :disabled="selectedDatasetsToGrant.length == 0" color="primary" fab :dark="selectedDatasetsToGrant.length > 0" x-large absolute bottom right>
                <v-icon>mdi-lock-open-plus</v-icon>
              </v-btn>
                </template>
                <span >Grant access to the selected sample</span>
              </v-tooltip>

            </template>
          </v-toolbar>
          <v-divider class="mb-7"></v-divider>
             <div class="mx-6 pt-3">
             <v-select  prepend-icon="mdi-cohort-variant" outlined  v-model="selectedCohort" :items="cohorts" item-text="title" item-value="id" label="Cohort"    ></v-select>
               </div>
          <v-divider class="mb-7"></v-divider>
          <v-list-item-group v-model="selectedDatasetsToGrant" multiple active-class="secondary--text font-weight-bold grey lighten-3" >
            <v-list-item v-for="dataset in allDatasets" :key="dataset.id" >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title v-text="dataset.name"></v-list-item-title>
                  <v-list-item-subtitle><v-icon>{{dataset.datasetType.id == 1 ? ' mdi-database' : 'mdi-database-eye'}}</v-icon> <b>{{dataset.datasetType.name}}</b>
                                 <v-icon class="ml-8">mdi-view-sequential</v-icon>   {{dataset.recordCount}} rows
                                 <v-icon class="ml-8">mdi-view-parallel</v-icon> {{dataset.columnCount}} columns </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { getDatasets, getCohorts} from '@/api/dataset.js'
  import { grantAccessToDatasets} from '@/api/users.js'

  export default {
    data () {
      return {
        userId : this.$route.params.id,
        selectedDatasetsToGrant: [],
        allDatasets: [],
        selectedCohort: 1,
        cohorts:[]
      }
    },
    created () {
      this.loadDatasets()
    },
    methods: {
      loadDatasets(){
          const _this = this
          getDatasets(function(res){
            _this.allDatasets = res
          })
          getCohorts(function(res){
            _this.cohorts = res
          })
      },
      grantAccess(){
        const payload ={
          datasets: this.selectedDatasetsToGrant.map( index => this.allDatasets[index].id )
        }
        const _this = this
        grantAccessToDatasets(this.userId,this.selectedCohort, payload, function(){
          _this.loadDatasets()
        }, 'Access to the selected databases granted successfully')
      }
    }
  }
</script>