<template>
  <div class="d-flex flex-wrap">
    <div  v-for="pkg in userCohorts" :key="pkg.id" sm="6" md="4" class="mx-4 my-4" style="width:400px;" >
      <UserCohortDatasets :userCohort="pkg" @onRevokeAccess="revokeAccess"/>
    </div>
  </div>
</template>

<script>
   import {getUserCohorts, revokeAccessToDatasets, getUserCohortDatasets} from '@/api/users.js'
   import  UserCohortDatasets from '@/components/UserCohortDatasets'

  export default {
    name:'UserSamples',
    components: {UserCohortDatasets},
    data () {
      return {
        userId : this.$route.params.id,
        userCohorts:[],
        userCohortDatasets:[]
      }
    },
    created () {
      this.loadDatasets()
    },
    methods: {
      loadDatasets(){
          const _this = this
          getUserCohorts(this.userId, function(res){
            _this.userCohorts = res.map(item => {
              item.datasets=[]
              return item
              })
            var i = 0;
            for( i = 0; i< _this.userCohorts.length; i++){
              getUserCohortDatasets(_this.userId, _this.userCohorts[i].id, function(res){
                const idx = _this.userCohorts.map(item => {return item.id}).indexOf(res.cohortId)
                _this.userCohorts[idx].datasets = res.datasets
              }
              )
            }
          })
      },
      revokeAccess(cohortId, datasets){
        const payload ={
          datasets: datasets
        }
        const _this = this
        revokeAccessToDatasets(this.userId, cohortId,  payload, function(){
          _this.loadDatasets()
        }, 'Access to the selected databases revoked successfully')
      }
    }
  }
</script>